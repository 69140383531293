import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import IndexView from './index_view';
import VisitsRow from './visits_row';

import VisitsActions from '../actions/visits_actions';

class ExplorersVisitsPanel extends IndexView {

  constructor(props){
    super(props);
    this.state = {};
  }

  render(){

    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.visits.toIndexedSeq().map(visit => {
      return <VisitsRow key={visit.id} visit={visit} />
    });


    return (
      <div>
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            {this.searchBar()}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-sm table-bordered table-hover table-striped">
              <thead>
                <tr>
                  {this.generateHeaders(VisitsRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) => {
    return VisitsActions.index(
      Object.assign(
        {},
        query,
        {filter: {'explorer_projects.explorer_id': this.props.explorer.id}}
      )
    ).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = (initialState,initialProps) => {
  const explorer = initialState.getIn(['explorers',initialProps.match.params.id]);
  return (state) => {
    const visits = state.get('visits').toIndexedSeq().filter((v) => v.explorer_id === explorer.id );
    return {
      explorer: explorer,
      visits: visits,
    };
  };
}

export default withRouter(connect(mapStateToProps)(ExplorersVisitsPanel));

