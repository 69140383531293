import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import VisitsActions from '../actions/visits_actions';

import VisitsFormPanel from './visits_form_panel';
import VisitsDependentsPanel from './visits_dependents_panel';
import VisitsImagesPanel from './visits_images_panel';
import VisitsMapPanel from './visits_map_panel';
import VisitsPayablesPanel from './visits_payables_panel';

import LiveDate from './live_date';

import { getAtt } from '../../shared/helpers/visit_observation_helpers';

class VisitsEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { 
      tab: 'details',
    };
  }

  componentDidMount(){
    VisitsActions.show(this.props.match.params.id).then(json => {
      this.props.loadForm({'visits/edit': json.data.attributes.response.data.attributes});
      this.setState({loaded: true});
    })
  }

  render(){
    if(this.state.loaded && this.props.business_categories_loaded){
      const { explorer_project,place_validations,visit,place,lead } = this.props;

      const subpath = this.props.location.pathname.match(/visits\/\d+\/(\w+)/)[1];

      let explorerLink;
      if(visit.observer_type == 'Explorer'){
        explorerLink = (
          <span>Explored by <Link to={`/explorers/${visit.observer_id}/edit`}>
            <span className="badge badge-primary">{visit.observer_id}</span>
          </Link></span>
        );
      }

      let validatorLink;

      // const pv = place_validations.last();
      // if(pv){
      //   if(pv.validated_at){
      //     validatorLink = (
      //       <p>
      //         <span className="h3"><span className={cn({'badge-success': pv.validated, 'badge-danger': !pv.validated},'badge')}>{pv.validated ? 'Validated' : 'Rejected'}</span></span>
      //         <span className="ml-2">by <span className="badge badge-info">{pv.validator_id}</span></span>
      //         <span className="ml-2"><LiveDate date={pv.validated_at}/></span>
      //         { pv.overridden_at ? <span className="">, Overrode <LiveDate date={pv.overridden_at} /></span> : null }
      //       </p>
      //     )
      //   } else {
      //     validatorLink = (
      //       <p>
      //         <span className="h3"><span className="badge badge-secondary">Pending</span></span>
      //         <span className="ml-2">assigned to <span className="badge badge-info">{pv.validator_id}</span></span>
      //         <span className="ml-2"><LiveDate date={pv.created_at}/></span>
      //       </p>
      //     );
      //   }
      // }

      const projectLink = (
        <React.Fragment>Project <Link to={`/projects/${this.props.project.id}/edit`}><span className="badge badge-danger">{this.props.project.key}</span></Link></React.Fragment>
      )

      let placeLink;
      if(place){
        placeLink = (
          <React.Fragment>Place <Link to={`/places/${place.id}/edit`}><span className="badge badge-success">{place.id}</span></Link></React.Fragment>
        )
      }

      let leadLink;
      if(lead){
        leadLink = (
          <React.Fragment>Lead <Link to={`/leads/${lead.id}/edit`}><span className="badge badge-info">{lead.id}</span></Link></React.Fragment>
        )
      }

      return (
        <div id="visitsShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-visit">{visit.id}</span> {getAtt(visit,'name') || place.name}</h2>
              <span className="h3 mb-2"><span className={cn({'badge-success': visit.validated, 'badge-danger': !visit.validated},'badge')}>{visit.validated ? 'Validated' : 'Rejected'}</span></span>
              <div className="clearfix mb-2"></div>
              {projectLink}{ placeLink ? ', ' : null }{ placeLink }{ leadLink ? ', ' : null }{ leadLink }
              <div className="clearfix mb-2"></div>
              <p>{explorerLink}, Submitted <LiveDate date={this.props.visit.created_at} />, updated <LiveDate date={this.props.visit.updated_at} /></p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'edit')})} to={`/visits/${visit.id}/edit`}>Edit</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'dependents')})} to={`/visits/${visit.id}/dependents`}>Dependents</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'images')})} to={`/visits/${visit.id}/images`}>Images</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'map')})} to={`/visits/${visit.id}/map`}>Map</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'payables')})} to={`/visits/${visit.id}/payables`}>Payables</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/visits/:id" component={VisitsFormPanel} />
                    <Route exact path="/visits/:id/edit" component={VisitsFormPanel} />
                    <Route exact path="/visits/:id/dependents" component={VisitsDependentsPanel} />
                    <Route exact path="/visits/:id/images" component={VisitsImagesPanel} />
                    <Route exact path="/visits/:id/map" component={VisitsMapPanel} />
                    <Route exact path="/visits/:id/payables" component={VisitsPayablesPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }
    
  }

  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }

}

const mapStateToProps = (state,props) => {
  let place;
  let project;
  let project_visit;
  let lead;

  const visit = state.getIn(['observations',props.match.params.id]);
  if(visit){
    if(visit.observable_id){
      place = state.getIn(['places',visit.observable_id.toString()]); 
    }
    project_visit = state.get('project_visits').filter(pv => pv.observation_id === visit.id).minBy(pv => pv.id);
    // explorer_project = state.getIn(['explorer_projects',visit.explorer_project_id.toString()]);
    if(project_visit){ 
      project = state.getIn(['projects',project_visit.project_id.toString()]);
    }
    if(visit.lead_id){
      lead = state.getIn(['leads',visit.lead_id.toString()]);
    }
  }

  return {
    visit,
    place,
    project_visit,
    project,
    lead,
    business_categories_loaded: !isEmpty(state.get('business_categories'))
  }
}

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};



export default connect(mapStateToProps,mapDispatchToProps)(VisitsEdit);
