import React from 'react';
import { connect } from 'react-redux';

import ExplorersPayableRow from './explorers_payable_row';

class VisitsPayablesPanel extends React.Component {
  render(){
    const { pricing_items, ...rest } = this.props;

    const rows = this.props.pricing_items.toIndexedSeq().map(pi => {
      return <ExplorersPayableRow key={pi.id} pricing_item={pi} onUpdate={this.refresh} {...rest} />
    });

    return (
      <div>
         <table className="table table-sm table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Condition</th>
              <th>Amount</th>
              <th>Earned</th>
              <th>Paid</th>
              <th>Paypal ID</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

}

const mapStateToProps = (state,props) => {
  let pricing_items;
  let payables;
  let payments;
  const visit = state.getIn(['observations',props.match.params.id]);
  if(visit){
    pricing_items = state.get('pricing_items').filter(pi => pi.pricing_structure_id === visit.pricing_structure_id)
    payables = state.get('payables').filter(p => p.observation_id === visit.id);
    const paymentIds = payables.map(p => p.payment_id);
    payments = state.get('payments').filter(p => paymentIds.includes(p.id));
  }
  return {
    visit: visit,
    pricing_items: pricing_items,
    payables: payables,
    payments: payments,
  };
};

export default connect(mapStateToProps)(VisitsPayablesPanel);