import React from 'react';
import { humanReadable } from '../helpers/date_time_helpers';

class ExplorersPayableRow extends React.PureComponent {

  render(){
    const { pricing_item } = this.props;
    const payable = this.props.payables.find(p => p.pricing_item_id === pricing_item.id )
    const payment = (payable ? this.props.payments.find(p => p.id === payable.payment_id) : null );
    return (
      <tr data-pi={pricing_item.id}  data-payable={payable ? payable.id : undefined} data-payment={payment ? payment.id : undefined}>
        <td>{pricing_item.key}</td>
        <td>${payable ? parseFloat(payable.amount).toFixed(2) : parseFloat(pricing_item.amount).toFixed(2)}</td>
        <td>{payable ? humanReadable(payable.created_at) : null}</td>
        <td>{payment ? humanReadable(payment.created_at) : null}</td>
        <td>{payment ? payment.vendor_batch_id : null}</td>
      </tr>
    )
  }
}

export default ExplorersPayableRow;